@import url("https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@100;300;400;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
}

@layer components {
}

@layer utilities {
}

body {
    font-family: "Londrina Solid", cursive;
    @apply bg-[#001833] text-gray-200;
}
